import logoLink from '../resources/momentum.png';
import companyLogoLink from '../resources/momentum.png';
import footerLogoLink from '../resources/momentum_emeye-t-inside_logo.svg';

export const cssVariables = {
    background_color: 'rgb(255, 255, 255)',
    //logo_max_width: '197px',

    primary_light: '#7077f4',
    primary: '#646BF3',
    text_on_primary: 'white',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#211A1D',
    text_on_secondary: 'white',
    focus_active: '#646BF3',

    font: '"SuisseIntl-Medium", Helvetica, Arial, sans-serif',
    link_color: '#646BF3',
    font_color: '#211A1D',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#787878',
    gridbox_header_text: 'white',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: 'red',
    green: '008000',
    connecting: '#c0c0c0',
    connected: '#008000',
    aborted: '#ff0000',
    paused: '#EF6E00',

    header_footer_seperator: '#646BF3',
    panel_highlight: '#646BF3',

    chat_background_right: '#D8DAFC',
    chat_background_left: '#DBDBDB',

    active: '#646BF3',
    inactive: '#211A1D',
    text_on_active: '#211A1D',
    text_on_inactive: '#211A1D',

    dropdown_background: '#D8DAFC',

    phonebook_dropdown: '#D8DAFC',
    phonebook_dropdown_svg: '#211A1D',

    pointer: '#646BF3',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = '';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
